<template>
  <s-modal
    :is-show="isShow"
    modal-title="Email Berhasil Dikirim"
    :modal-text="`Silakan cek <span class='font-label-md-sb-lh16'>${ email }</span> untuk mengakses tautan pengaturan ulang kata sandi.`"
    modal-image="https://cdn.sekolah.mu/assets/sso/popup/email-sudah-terkirim.png"
    primary-button-text="Mengerti"
    show-header-close-btn
    @closeModal="closeModal"
    @primaryButtonClick="closeModal"
    />
</template>

<script>
import SModal from '@/components/molecules/modals/SModal'
export default {
  name: 'EmailSentModal',
  props: {
    email: String,
    isShow: Boolean
  },
  components: {
    SModal
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style>

</style>
