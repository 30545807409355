<template>
  <button
    id="oauth-btn"
    @click="onClick()"
    :disabled="isLoading"
    class="oauth-btn font-subtitle-4"
    :class="[{'pill-button': pill}]">
    <img :src="logoImage" v-if="!isLoading" :alt="logoAlt"/>
    <div class="spinner spinner-border text-primary" role="status" v-else>
      <span class="sr-only">Loading...</span>
    </div>
    <span class="oauth-text font-label-md-sb-lh24">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'OauthButton',
  props: {
    isLoading: Boolean,
    pill: Boolean,
    logoImage: String,
    logoAlt: String
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style>
.oauth-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--color-ui-element-neutral-light-2);
  outline: none;
  padding: 8px 47px;
}
.oauth-btn img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.oauth-btn .spinner {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}
.oauth-btn .oauth-text {
  color: var(--color-ui-text-primary);
}

/* pill button style */
.pill-button {
  border-radius: 24px !important;
}
</style>
