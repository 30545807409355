<template>
  <s-modal
    :is-show="isShow"
    modal-title="Kata Sandimu Sudah Kedaluwarsa"
    modal-text="Silakan reset kata sandi untuk bisa masuk kembali ke akunmu."
    modal-image="https://cdn.sekolah.mu/assets/sso/popup/login-password-sudah-kedaluwarsa.png"
    primary-button-text="Reset Kata Sandi"
    no-close-on-backdrop
    no-close-on-esc
    @primaryButtonClick="closeModal"
    />
</template>

<script>
import SModal from '@/components/molecules/modals/SModal'

export default {
  name: 'ExpiredPasswordModal',
  props: {
    isShow: Boolean
  },
  components: {
    SModal
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style>

</style>
