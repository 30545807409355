<template>
  <div id="s-input" :class="wrapperClasses">
    <input
      id="s-input-text-field"
      :value="formatter(value)"
      :type="type"
      :maxlength="maxlength"
      :disabled="disabled"
      class="input"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      @input="onInput"
      @focus="toggleFocus"
      @blur="toggleFocus"
    >
    <p id="s-input-text-placeholder" :class="{ placeholder: true }">
      {{ placeholder }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SInputText',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'placeholder'
    },
    isError: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function,
      default: (value) => value
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        return ['text', 'password', 'email'].includes(value)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFocus: false
    }
  },
  computed: {
    wrapperClasses () {
      return {
        's-input-wrapper': true,
        'base-border': true,
        'is-error error-border': this.isError,
        'is-focused focus-border': this.isFocus,
        'default-border': !this.isFocus,
        'is-filled': this.value
      }
    }
  },
  methods: {
    onInput (e) {
      this.$emit('input', this.formatter(e.target.value))
    },
    toggleFocus () {
      this.isFocus = !this.isFocus
    }
  }
}
</script>

<style scoped>

.s-input-wrapper {
  position: relative;
  z-index: 1;
  min-width: 200px;
  width: 100%;
  height: 44px;
  font-family: "Inter", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  transition: padding 0s;
}

.s-input-wrapper .input {
  border: none;
  background: none;
  color: black;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-right: 22px;
  padding-left: 12px;
  color: var(--color-ui-element-black);
  border-radius: 8px;

  transition: margin 0s;
}

.s-input-wrapper .input ~ .placeholder {
  /* Positioning Behind Input */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  /* Styling */
  letter-spacing: 0px;
  text-align: left;
  padding: 10px 12px 0 12px;
  color: var(--color-text-placeholder);
  background: none;
  width: max-content;
  margin-bottom: 0;

  /*transition: font-size 0.3s, padding-top 0.3s;*/
  transition: font-size 0.3s, top 0.3s, left 0.3s, background 0.3s, padding 0.3s;
}

.s-input-wrapper .input:-webkit-autofill ~ .placeholder,
.s-input-wrapper.is-focused .input ~ .placeholder,
.s-input-wrapper.is-filled .input ~ .placeholder {
  padding: 2px 4px 2px 4px;
  top: -11px;
  left: 8px;
  background: var(--color-background-white);
  font-size: 12px;
  line-height: 16px;
  z-index: 1;
  border-radius: 5px;
}

.s-input-wrapper.is-focused .input ~ .placeholder {
  color: var(--color-persian-blue-500);
}

.s-input-wrapper.is-error:not(.is-focused):not(.is-filled) .input ~ .placeholder {
  color: var(--color-text-placeholder) !important;

}

.s-input-wrapper.is-error .input ~ .placeholder {
  color: var(--color-ui-element-red-500);
}

.s-input-wrapper .input:disabled {
  color: var(--color-text-placeholder)
}

.input:focus, .input:valid {
  outline: none;
}

.base-border {
  box-sizing: border-box;
  border-radius: 8px;
}

.default-border {
  border: 1px solid var(--disable-color);
}

.focus-border {
  border: 1px solid var(--color-persian-blue-500);
}

.error-border {
  border: 1px solid var(--color-ui-element-red-500);
}
</style>
