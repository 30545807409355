<template>
  <div>
    <template v-if="isMobile">
      <transition name="fade" mode="out-in">
        <register-form v-if="!isRegisterOauthFormOpen"
          :source="source"
          @registerByOauth="openRegisterOauthFormHandler"
          @registerSuccess="registerSuccessHandler">
        </register-form>
        <register-oauth-form v-else
          :source="source"
          :oauth-user-information="oauthUserInformation"
          @registerSuccess="registerSuccessHandler">
        </register-oauth-form>
      </transition>
    </template>
    <template v-else>
      <div class="card">
        <div class="card-body">
          <transition name="fade" mode="out-in">
            <register-form v-if="!isRegisterOauthFormOpen"
              :source="source"
              @registerByOauth="openRegisterOauthFormHandler"
              @registerSuccess="registerSuccessHandler"
              >
            </register-form>
            <register-oauth-form v-else
              :source="source"
              :oauth-user-information="oauthUserInformation"
              @registerSuccess="registerSuccessHandler">
            </register-oauth-form>
          </transition>
        </div>
      </div>
    </template>

    <platform-redirection-modal
      :is-show="isPlatformRedirectionModalShow"
      @selectPlatform="selectPlatformHandler"
      @closeModal="closePlatformRedirectionModalHandler"
      />
  </div>
</template>

<script>
import deviceDetector from 'mobile-device-detect'
import RegisterForm from '@/components/Authorization/RegisterForm.vue'
import RegisterOauthForm from '@/components/Authorization/RegisterOauthForm.vue'
import SourcePlatformMixin from '@/mixins/sourcePlatformMixin'
import UserAgentMixin from '@/mixins/userAgentMixin'
import PlatformRedirectionModal from '@/components/molecules/modals/PlatformRedirectionModal'

export default {
  name: 'Register',
  mixins: [SourcePlatformMixin, UserAgentMixin],
  components: {
    RegisterForm,
    RegisterOauthForm,
    PlatformRedirectionModal
  },
  data: () => ({
    isMobile: deviceDetector.isMobile,
    isRegisterOauthFormOpen: false,
    oauthUserInformation: null,

    isPlatformRedirectionModalShow: false
  }),
  computed: {
    source () {
      return this.$store.state.sourcePlatform
    }
  },
  methods: {
    selectPlatformHandler (platform) {
      this.storeSourcePlatform(platform)
      this.storeUserAgent()

      this.closePlatformRedirectionModalHandler()
    },

    closeMergerAnnouncementModalHandler () {
      this.isMergerAnnouncementModalShow = false
    },
    closePlatformRedirectionModalHandler () {
      this.isPlatformRedirectionModalShow = false
    },

    openRegisterOauthFormHandler (oauthUserInformation) {
      this.isRegisterOauthFormOpen = true
      this.oauthUserInformation = oauthUserInformation
    },
    registerSuccessHandler (token) {
      this.redirectToSourcePlatform(token)
    }
  },
  created () {
    this.isRegisterOauthFormOpen = this.$store.state.isRegisterOauthFormOpen
    this.oauthUserInformation = this.$store.state.oauthUserInformation

    if (!this.source) {
      this.isPlatformRedirectionModalShow = true
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 10px;
  border-color: var(--color-ui-element-neutral-light-2);
}
.card-body {
  padding: 36px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
