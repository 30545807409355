<template>
  <b-modal
    v-model="isShow"
    content-class="modal-content-semi_round"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    size="s_modal"
    @hide="closeModal"
    >
    <div class="text-center">
      <h2 class="modal-title font-label-xl-sb">Pilih Platform</h2>
      <div class="platform-container">
        <div class="platform-item">
          <button class="btn platform-btn" @click="onPlatformSelect('rencanamu')">
            <img src="https://rencanamu.id/assets/frontend/img/rencanamu-mini-logo-red.png" alt="Rencanamu" class="platform-logo img-fluid">
          </button>
          <span class="platform-name font-label-md-rg-lh16">Rencanamu</span>
        </div>
        <div class="platform-item">
          <button class="btn platform-btn" @click="onPlatformSelect('talentics')">
            <img src="https://talentics.id/images/talentics-mini-logo-purple-new.svg" alt="Talentics" class="platform-logo img-fluid">
          </button>
          <span class="platform-name font-label-md-rg-lh16">Talentics</span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PlatformRedirectionModal',
  props: {
    isShow: Boolean
  },
  methods: {
    onPlatformSelect (platform) {
      this.$emit('selectPlatform', platform)
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
.modal-title {
  color: var(--color-persian-blue-700);
  margin: 20px 0;
}
.platform-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
}
.platform-item {
  text-align: center;
}
.platform-btn {
  border-radius: 15px;
  padding: 10px;
  width: 72px;
  height: 72px;
  background: white;
  box-shadow: 0px 2px 8px var(--color-ui-element-neutral-light-3);
  margin-bottom: 12px;
}
.platform-logo {
  width: 100%;
}
.platform-name {
  color: var(--color-ui-element-black);
  display: block;
}
</style>
