<template>
  <div>
    <template v-if="isMobile">
      <login-form
        :source="source"
        @passwordExpired="passwordExpiredHandler"
        @openResetPasswordModal="openResetPasswordModalHandler"
        @loginSuccess="loginSuccessHandler"
        @registerByOauth="redirectToRegisterOauthFormHandler">
      </login-form>
    </template>
    <template v-else>
      <div class="card">
        <div class="card-body">
          <login-form
            :source="source"
            @passwordExpired="passwordExpiredHandler"
            @openResetPasswordModal="openResetPasswordModalHandler"
            @loginSuccess="loginSuccessHandler"
            @registerByOauth="redirectToRegisterOauthFormHandler">
          </login-form>
        </div>
      </div>
    </template>

    <expired-password-modal
      :is-show="isExpiredPasswordModalShow"
      @closeModal="closeExpiredPasswordModalHandler"
      />

    <email-sent-modal
      :email="email"
      :is-show="isEmailSentModalShow"
      @closeModal="closeEmailSentModalHandler"
      />

    <reset-password-modal
      :predefined-email="email"
      :source="source"
      :is-show="isResetPasswordModalShow"
      :is-for-forgot-password="!isPasswordExpired"
      @closeModal="closeResetPasswordModalHandler"
      @confirmationEmailSent="resetPasswordSentHandler"
      />

    <platform-redirection-modal
      :is-show="isPlatformRedirectionModalShow"
      @selectPlatform="selectPlatformHandler"
      @closeModal="closePlatformRedirectionModalHandler"
      />
  </div>
</template>

<script>
import deviceDetector from 'mobile-device-detect'
import LoginForm from '@/components/Authorization/LoginForm'
import SourcePlatformMixin from '@/mixins/sourcePlatformMixin'
import UserAgentMixin from '@/mixins/userAgentMixin'
import ResetPasswordModal from '@/components/molecules/modals/ResetPasswordModal'
import ExpiredPasswordModal from '@/components/molecules/modals/ExpiredPasswordModal'
import EmailSentModal from '@/components/molecules/modals/EmailSentModal'
import PlatformRedirectionModal from '@/components/molecules/modals/PlatformRedirectionModal'

export default {
  components: {
    LoginForm,
    ResetPasswordModal,
    ExpiredPasswordModal,
    EmailSentModal,
    PlatformRedirectionModal
  },
  mixins: [SourcePlatformMixin, UserAgentMixin],
  data () {
    return {
      isMobile: deviceDetector.isMobile,
      isPasswordExpired: false,

      isExpiredPasswordModalShow: false,
      isResetPasswordModalShow: false,
      isEmailSentModalShow: false,

      email: null,

      isPlatformRedirectionModalShow: false
    }
  },
  computed: {
    source () {
      return this.$store.state.sourcePlatform
    }
  },
  methods: {
    selectPlatformHandler (platform) {
      this.storeSourcePlatform(platform)
      this.storeUserAgent()

      this.closePlatformRedirectionModalHandler()
    },

    openResetPasswordModalHandler () {
      this.isResetPasswordModalShow = true
      this.isPasswordExpired = false
      this.email = null
    },
    closeResetPasswordModalHandler () {
      this.isResetPasswordModalShow = false
    },
    resetPasswordSentHandler (email) {
      this.isResetPasswordModalShow = false
      this.isEmailSentModalShow = true

      this.email = email
    },
    closeEmailSentModalHandler () {
      this.isEmailSentModalShow = false
    },
    closePlatformRedirectionModalHandler () {
      this.isPlatformRedirectionModalShow = false
    },

    passwordExpiredHandler (email) {
      this.isPasswordExpired = true
      this.isExpiredPasswordModalShow = true
      this.email = email
    },
    closeExpiredPasswordModalHandler () {
      this.isExpiredPasswordModalShow = false
      this.isResetPasswordModalShow = true
    },

    redirectToRegisterOauthFormHandler (oauthUserInformation) {
      this.$store.commit('CHANGE_IS_REGISTER_OAUTH_FORM_OPEN', true)
      this.$store.commit('CHANGE_OAUTH_USER_INFORMATION', oauthUserInformation)

      this.$router.push('/register')
    },

    loginSuccessHandler (token) {
      this.redirectToSourcePlatform(token)
    }
  },
  created () {
    if (!this.source) {
      this.isPlatformRedirectionModalShow = true
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 10px;
  border-color: var(--color-ui-element-neutral-light-2);
}
.card-body {
  padding: 36px;
}
</style>
