import axios from 'axios'
import deviceDetector from 'mobile-device-detect'
import generateQueryParamSource from '@/functions/generateQueryParamSource'

const axiosServer = axios.create({
  baseURL: `${process.env.VUE_APP_URL_SERVER}`
})

axiosServer.interceptors.request.use(
  function (req) {
    let traceSource = ''
    if (req.generateQueryParamSource) {
      const isMobile = deviceDetector.isMobile
      traceSource = generateQueryParamSource('sekolah.mu', isMobile)
    }

    req.url = req.url.includes('?')
      ? req.url + '&' + traceSource
      : req.url + '?' + traceSource
    return req
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axiosServer
