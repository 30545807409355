import axiosServer from '@/configs/axiosServer'

const baseUrl = '/user'

export default {
  async profile ({ headers, payload }) {
    try {
      const { data } = await axiosServer({
        url: baseUrl + '/profile/',
        method: 'post',
        headers: {
          ...headers
        },
        data: {
          ...payload
        }
      })

      return data
    } catch (error) {
      return {
        status: 500,
        message: `${error}`
      }
    }
  }
}
