<template>
  <div>
    <s-alert
      id="error-alert"
      variant="danger"
      :show-alert="isErrorAlertShow"
      @closeAlert="closeAlert()"
      >
      {{ formInvalidFormMessage }}
    </s-alert>

    <div class="main-container container">
      <h1 class="title font-title-m-h1 text-center">Ubah Kata Sandi</h1>

      <div class="email-container">
        <label class="email-label font-subtitle-sb-5 d-block">Email:</label>
        <h6 class="email-content font-label-md-sb-lh16 d-block">{{ email }}</h6>
      </div>

      <form @submit.prevent="submit">
        <div class="form-group">
          <div class="position-relative">
            <s-input-text
              id="password-input"
              v-model="password"
              :type="passwordInputType"
              :is-error="!isPasswordValid"
              placeholder="Kata sandi baru"
            />
            <div class="password-eye-icon-container">
              <img :src="passwordToggleIcon"
                alt="Show/Hide Password"
                id="password-input-type-button"
                @click="togglePasswordInputType()"
                />
            </div>
          </div>
          <template v-if="!isPasswordValid">
            <div class="input-information-container">
              <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
              <span
                id="password-error-message-label"
                class="input-information-label is-invalid font-subtitle-rg-5">
                {{ passwordInvalidErrorMessage }}
              </span>
            </div>
          </template>
          <template v-else>
            <span
              id="password-information-label"
              class="input-information-label font-subtitle-rg-5"
            >
              Minimal 8 karakter kombinasi huruf besar, huruf kecil, dan angka
            </span>
          </template>
        </div>
        <div class="form-group">
          <div class="position-relative">
            <s-input-text
              id="repeat-password-input"
              v-model="repeatPassword"
              :type="repeatPasswordInputType"
              :is-error="!isRepeatPasswordValid"
              placeholder="Konfirmasi kata sandi baru"
            />
            <div class="password-eye-icon-container">
              <img :src="repeatPasswordToggleIcon"
                alt="Show/Hide Password"
                id="repeat-password-input-type-button"
                @click="toggleRepeatPasswordInputType()"
                />
            </div>
          </div>
          <template v-if="!isRepeatPasswordValid">
            <div class="input-information-container">
              <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
              <span
                id="password-error-message-label"
                class="input-information-label is-invalid font-subtitle-rg-5">
                {{ repeatPasswordInvalidErrorMessage }}
              </span>
            </div>
          </template>
          <template v-else>
            <span
              id="password-information-label"
              class="input-information-label font-subtitle-rg-5"
            >
              Minimal 8 karakter kombinasi huruf besar, huruf kecil, dan angka
            </span>
          </template>
        </div>

        <div class="form-group button-container">
          <s-button
            id="submit-button"
            variant="primary"
            :is-loading="isFormLoading"
            disabled-button-text-color="--color-ui-text-tertiary-2"
            pill
            block
          >
            Ubah Kata Sandi
          </s-button>
        </div>
      </form>
    </div>
    <mini-footer />
  </div>
</template>

<script>
import SInputText from '@/components/atoms/inputs/SInputText'
import SButton from '@/components/atoms/buttons/SButton'
import SAlert from '@/components/atoms/alerts/SAlert'
import MiniFooter from '@/components/MiniFooter'
import auth from '@/api/auth'

export default {
  name: 'ResetPassword',
  components: {
    SInputText,
    SButton,
    SAlert,
    MiniFooter
  },
  data: () => ({
    email: null,
    password: null,
    repeatPassword: null,

    passwordInputType: 'password',
    repeatPasswordInputType: 'password',

    isPasswordValid: true,
    isRepeatPasswordValid: true,

    passwordInvalidErrorMessage: null,
    repeatPasswordInvalidErrorMessage: null,

    isFormLoading: false,

    isErrorAlertShow: false,
    formInvalidFormMessage: null
  }),
  computed: {
    passwordToggleIcon () {
      return this.passwordInputType === 'password' ? 'https://cdn.sekolah.mu/assets/sso/eye-icon.svg' : 'https://cdn.sekolah.mu/assets/sso/eye-slash-icon.svg'
    },
    repeatPasswordToggleIcon () {
      return this.repeatPasswordInputType === 'password' ? 'https://cdn.sekolah.mu/assets/sso/eye-icon.svg' : 'https://cdn.sekolah.mu/assets/sso/eye-slash-icon.svg'
    }
  },
  methods: {
    getEmail () {
      this.email = window.atob(this.$route.query.verification)
    },

    togglePasswordInputType () {
      this.passwordInputType =
        this.passwordInputType === 'password' ? 'text' : 'password'
    },
    toggleRepeatPasswordInputType () {
      this.repeatPasswordInputType =
        this.repeatPasswordInputType === 'password' ? 'text' : 'password'
    },

    // Validation
    validatePassword () {
      if (!this.password) {
        this.isPasswordValid = false
        this.passwordInvalidErrorMessage = 'Masukkan kata sandi baru.'

        return false
      }

      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(this.password)) {
        this.isPasswordValid = false
        this.passwordInvalidErrorMessage = 'Format kata sandi tidak sesuai. Periksa dan coba lagi.'

        return false
      }
    },
    validateRepeatPassword () {
      if (!this.repeatPassword) {
        this.isRepeatPasswordValid = false
        this.repeatPasswordInvalidErrorMessage = 'Masukkan konfirmasi kata sandi baru.'

        return false
      }

      if (this.repeatPassword !== this.password) {
        this.isRepeatPasswordValid = false
        this.repeatPasswordInvalidErrorMessage = 'Konfirmasi kata sandi baru tidak sesuai dengan kata sandi baru. Periksa dan coba lagi.'

        return false
      }
    },
    validateForm () {
      this.validatePassword()
      this.validateRepeatPassword()

      if (!(this.isPasswordValid && this.isRepeatPasswordValid)) {
        return false
      }

      return true
    },

    async submit () {
      if (!this.validateForm()) {
        return false
      }

      try {
        this.isFormLoading = true

        const data = await auth.resetPassword({
          payload: {
            email: this.$route.query.verification,
            code: this.$route.query.code,
            new_password: this.password
          }
        })

        if (data.status === 200) {
          this.$store.commit('CHANGE_IS_RESET_PASSWORD_SUCCESS', true)
          this.$router.push('/login')
        } else if (data.status === 400) {
          this.isErrorAlertShow = true
          this.formInvalidFormMessage = data.message
        }
      } finally {
        this.isFormLoading = false
      }
    },
    closeAlert () {
      this.isErrorAlertShow = false
    }
  },
  watch: {
    password: function (newPassword) {
      if (this.isPasswordValid) {
        return false
      }

      this.isPasswordValid = true
      this.passwordInvalidErrorMessage = null
    },
    repeatPassword: function (newPassword) {
      if (this.isRepeatPasswordValid) {
        return false
      }

      this.isRepeatPasswordValid = true
      this.repeatPasswordInvalidErrorMessage = null
    }
  },
  created () {
    this.getEmail()
  }
}
</script>

<style scoped>
.main-container {
  margin-top: 32px;
  max-width: 400px;
  min-height: calc(100vh - 290px);
  margin-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .main-container {
    margin-top: 56px;
  }
}

.title {
  color: var(--color-persian-blue-700);
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .title {
    margin-bottom: 40px;
  }
}

.email-container {
  margin-bottom: 24px;
}
.email-container .email-label {
  color: var(--color-ui-text-secondary);
  margin-bottom: 12px;
}
.email-container .email {
  color: var(--main-font-color);
}

.password-eye-icon-container {
  position: absolute;
  color: var(--color-ui-element-neutral-light-1);
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 18px;
  z-index: 1;
  display: flex;
  cursor: pointer;
}

.input-information-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.input-information-label {
  margin-top: 2px;
  margin-left: 4px;
  color: var(--color-text-placeholder);
  width: 95%;
}
.input-information-label.is-invalid {
  color: var(--color-ui-element-red-500);
}

.button-container {
  margin-top: 32px;
}
</style>
