<template>
  <b-modal
    v-bind="$attrs"
    v-model="isShow"
    content-class="modal-content-semi_round"
    centered
    hide-footer
    hide-header
    size="s_modal"
  >
    <template v-if="showHeaderCloseBtn">
      <div class="close-btn-container">
        <button
          class="btn"
          @click="closeModal()">
          <img src="https://cdn.sekolah.mu/assets/sso/close-icon.svg" alt="Close"/>
        </button>
      </div>
    </template>

    <div class="text-center pt-3 modal-wrapper">
      <b-img-lazy
        :src="imgSrc"
        class="image-modal"
      ></b-img-lazy>

      <h2 class="font-label-xl-sb px-3 modal-title">{{ modalTitle }}</h2>

      <p class="font-label-md-rg-lh16 modal-text" v-html="sanitizeXSS(modalText)"></p>

      <template v-if="!!modalTextSecondary">
        <p class="font-meta-11-rg modal-text-secondary" v-html="sanitizeXSS(modalTextSecondary)"></p>
      </template>

      <div class="modal-button-container">
        <s-button
          variant="primary"
          @click="primaryButtonClick()"
          class="modal-primary-button"
          pill
          block>
          {{ primaryButtonText }}
        </s-button>

        <template v-if="secondaryButtonText">
          <s-button
            variant="link"
            @click="secondaryButtonClick()"
            class="modal-secondary-button"
            pill
            block>
            {{ secondaryButtonText }}
          </s-button>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
import SButton from '@/components/atoms/buttons/SButton.vue'

export default {
  name: 'SModal',
  props: {
    isShow: Boolean,
    variant: {
      type: String,
      default: 'custom'
    },
    modalTitle: String,
    modalText: String,
    modalTextSecondary: String,
    primaryButtonText: {
      type: String,
      default: 'Ya'
    },
    secondaryButtonText: String,
    showHeaderCloseBtn: Boolean,
    modalImage: String
  },
  components: {
    SButton
  },
  methods: {
    sanitizeXSS (htmlEmbed) {
      return this.$XSSFilter(htmlEmbed)
    },
    closeModal () {
      this.$emit('closeModal')
    },
    primaryButtonClick () {
      this.$emit('primaryButtonClick')
    },
    secondaryButtonClick () {
      this.$emit('secondaryButtonClick')
    }
  },
  computed: {
    imgSrc () {
      switch (this.variant) {
        case 'custom':
          return this.modalImage
        default:
          return ''
      }
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  padding: 8px;
  padding-bottom: 0px;
}

.close-btn-container {
  display: flex;
  justify-content: flex-end;
}
.close-btn-container .btn {
  padding: 0;
}

.image-modal {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 20px;
}

.modal-title {
  color: var(--color-persian-blue-700);
  margin-bottom: 8px;
}

.modal-text {
  color: var(--color-ui-text-primary);
  margin: 0;
}

.modal-text-secondary {
  color: var(--color-ui-text-secondary-2);
  margin-top: 8px;
}

.modal-button-container {
  margin-top: 24px;
}

.modal-primary-button,
.modal-secondary-button {
  margin-bottom: 6px;
}

</style>
