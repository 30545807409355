<template>
  <b-modal
    v-model="isShow"
    content-class="modal-content-semi_round"
    centered
    hide-footer
    hide-header
    size="s_modal"
    @hide="closeModal"
    >
      <div class="close-btn-container">
        <button
          class="btn"
          @click="closeModal">
          <img src="https://cdn.sekolah.mu/assets/sso/close-icon.svg" alt="Close"/>
        </button>
      </div>
      <div class="pt-3 modal-wrapper">
        <h2 class="font-title-xl-h4 text-center modal-title">{{ modalTitle }}</h2>
        <p class="font-label-md-rg-lh16 text-center modal-text">
          Masukkan email yang terdaftar. Link reset kata sandi akan dikirim ke email.
        </p>

        <form @submit.prevent="submit()">
          <div class="form-group">
            <email-auto-complete
              id="email-auto-complete-input"
              v-model="email"
              :initial-value="email"
              :domains="domains"
              :default-domains="defaultDomains"
              :max-suggestions="5"
              :isError="!isEmailValid"
              placeholder="Email"
            />
            <template v-if="!isEmailValid">
              <div class="input-information-container">
                <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
                <span
                  id="email-error-message-label"
                  class="input-information-label is-invalid font-subtitle-rg-5">
                  {{ emailInvalidErrorMessage }}
                </span>
              </div>
            </template>
          </div>

          <s-button
            id="submit-input"
            variant="primary"
            pill
            class="w-100"
            :is-loading="isFormLoading"
          >Kirim</s-button>
        </form>
      </div>
  </b-modal>
</template>

<script>
import EmailAutoComplete from '@/components/atoms/inputs/EmailAutoComplete'
import SButton from '@/components/atoms/buttons/SButton'
import auth from '@/api/auth'

export default {
  name: 'ResetPasswordModal',
  props: {
    isShow: Boolean,
    isForForgotPassword: Boolean,
    predefinedEmail: String,
    source: String
  },
  components: {
    EmailAutoComplete,
    SButton
  },
  data () {
    return {
      email: '',
      isFormLoading: false,

      isEmailValid: true,
      emailInvalidErrorMessage: true,

      // email autocomplete
      domains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ],
      defaultDomains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ]
    }
  },
  computed: {
    modalTitle () {
      if (this.isForForgotPassword) {
        return 'Lupa Kata Sandi?'
      }

      return 'Reset Kata Sandi'
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },

    // Validations
    validateEmail () {
      if (!this.email) {
        this.isEmailValid = false
        this.emailInvalidErrorMessage = 'Masukkan alamat email.'

        return false
      }

      if (!/^[^@ ]+@[^@ ]+\.[^@ ]+$/.test(this.email)) {
        this.isEmailValid = false
        this.emailInvalidErrorMessage = 'Format email tidak sesuai. Periksa dan coba lagi.'

        return false
      }
    },
    validateForm () {
      this.validateEmail()

      if (!this.isEmailValid) {
        return false
      }

      return true
    },

    async submit () {
      if (!this.validateForm()) {
        return false
      }

      try {
        this.isFormLoading = true

        const data = await auth.sendResetPassword({
          payload: {
            email: this.email,
            source: this.source
          }
        })

        if (data.status === 200 || (data.status === 400 && data.message === 'record not found')) {
          this.$emit('confirmationEmailSent', this.email)
        }
      } finally {
        this.isFormLoading = false
      }
    }
  },
  watch: {
    predefinedEmail (newEmail) {
      this.email = newEmail || ''
    },
    email: function (newEmail) {
      if (this.isEmailValid) {
        return false
      }

      this.isEmailValid = true
      this.emailInvalidErrorMessage = null
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  padding: 8px;
  padding-bottom: 0px;
}

.close-btn-container {
  display: flex;
  justify-content: flex-end;
}
.close-btn-container .btn {
  padding: 0;
}

.modal-title {
  color: var(--color-persian-blue-700);
  margin-bottom: 8px;
}
.modal-text {
  color: var(--color-ui-text-primary);
  margin-bottom: 24px;
}

.input-information-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.input-information-label {
  margin-left: 4px;
  color: var(--color-text-placeholder);
}
.input-information-label.is-invalid {
  color: var(--color-ui-element-red-500);
  margin-left: 5px;
}

</style>
