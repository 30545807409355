import { render, staticRenderFns } from "./SModal.vue?vue&type=template&id=f0d9b238&scoped=true&"
import script from "./SModal.vue?vue&type=script&lang=js&"
export * from "./SModal.vue?vue&type=script&lang=js&"
import style0 from "./SModal.vue?vue&type=style&index=0&id=f0d9b238&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0d9b238",
  null
  
)

export default component.exports