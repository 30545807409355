<template>
  <div>
    <h1 class="title font-body-1 text-center">Masuk</h1>
    <p class="sub-title font-paragraph-4 text-center">
      <span>Belum punya akun? </span>
      <router-link to="register" class="ml-1">Daftar Sekarang</router-link>
    </p>

    <div class="form-container">
      <form @submit.prevent="login()">
        <div class="form-group">
          <email-auto-complete
            id="email-auto-complete-input"
            v-model="email"
            :domains="domains"
            :default-domains="defaultDomains"
            :max-suggestions="5"
            :is-error="!!formInvalidErrorMessage"
            placeholder="Email"
          />
        </div>
        <div class="form-group mb-1">
          <div class="position-relative">
            <s-input-text
              id="password-input"
              v-model="password"
              :type="passwordInputType"
              :is-error="!!formInvalidErrorMessage"
              placeholder="Kata sandi"
            />
            <div class="password-eye-icon-container">
              <img :src="passwordToggleIcon"
                alt="Show/Hide Password"
                id="password-input-type-button"
                @click="togglePasswordInputType()"
                />
            </div>
          </div>
        </div>

        <span
          id="form-error-message-label"
          v-if="formInvalidErrorMessage"
          class="form-error-message font-label-sm-rg">
          {{ formInvalidErrorMessage }}
        </span>

        <div class="form-additional-container">
          <div class="form-check">
            <input
              type="checkbox"
              id="keep-login-checkbox"
              class="keep-login-checkbox form-check-input font-label-sm-rg"
              v-model="keepLogin"
            >
            <label for="keep-login-checkbox" class="font-body-4 form-check-label label-keep-login"> Ingat saya</label>
          </div>
          <s-button
            id="forgot-password-button"
            type="button"
            variant="link"
            class="forgot-password font-label-sm-sb"
            @click="openResetPasswordModal()"
          >
            Lupa Kata Sandi?
          </s-button>
        </div>

        <div class="d-flex justify-content-center">
          <div class="button-wrapper w-100">
            <s-button
              id="submit-button"
              variant="primary"
              :is-loading="isFormLoading"
              disabled-button-text-color="--color-ui-text-tertiary-2"
              pill
              block>
              Masuk
            </s-button>
          </div>
        </div>
      </form>

      <div class="d-flex justify-content-center">
        <div class="button-wrapper">
          <div class="login-separator">
            <hr>
            <div class="font-body-3">atau</div>
            <hr>
          </div>

          <google-button class="google-oauth-button" @handleCredential="googleOauth" />
          <oauth-button
            id="linkedin-oauth-button"
            :is-loading="isLinkedinOauthLoading"
            logo-image="https://cdn.sekolah.mu/assets/logo/oauth/linkedin-logo.svg"
            logo-alt="LinkedIn"
            pill
            @click="linkedinOauth()"
            >
            Masuk dengan LinkedIn
          </oauth-button>
        </div>
      </div>
    </div>

    <div class="help-report font-label-sm-rg">
      <span class="d-block">Lapor kendala masuk ke</span>
      <a :href="whatsappHelpChatUrl" target="_blank" class="whatsapp-link font-label-sm-sb">
        <font-awesome-icon
          id="whatsapp-icon"
          :icon="['fab', 'whatsapp']"
          class="whatsapp-icon" size="lg" />
        <span>{{ whatsappHelpPhoneFormatted }} (Chat only)</span>
      </a>
    </div>
  </div>
</template>

<script>
import auth from '@/api/auth'
import EmailAutoComplete from '@/components/atoms/inputs/EmailAutoComplete'
import OauthButton from '@/components/atoms/buttons/OauthButton'
import SInputText from '@/components/atoms/inputs/SInputText'
import SButton from '@/components/atoms/buttons/SButton'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'LoginForm',
  props: {
    source: String
  },
  components: {
    OauthButton,
    EmailAutoComplete,
    SInputText,
    SButton,
    FontAwesomeIcon,
    GoogleButton: () => import('@/components/atoms/buttons/GoogleButton.vue')
  },
  data () {
    return {
      email: null,
      password: null,
      passwordInputType: 'password',
      keepLogin: false,

      isGoogleOauthLoading: false,
      isLinkedinOauthLoading: false,
      isFormLoading: false,

      formInvalidErrorMessage: null,

      whatsappHelpPhone: '62859106558206',

      // email autocomplete
      domains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ],
      defaultDomains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ]
    }
  },
  computed: {
    whatsappHelpPhoneFormatted () {
      return this.whatsappHelpPhone
        .replace(/^62/, 0)
        .replace(/(\d{4})(\d{4})(\d)/, '$1-$2-$3')
    },
    whatsappHelpChatUrl () {
      return `https://wa.me/${this.whatsappHelpPhone}?text=Hai%2C+saya+ingin+bertanya+tentang+akun+saya%21`
    },
    passwordToggleIcon () {
      return this.passwordInputType === 'password' ? 'https://cdn.sekolah.mu/assets/sso/eye-icon.svg' : 'https://cdn.sekolah.mu/assets/sso/eye-slash-icon.svg'
    }
  },
  methods: {
    unsetInvalidErrorMessages () {
      this.formInvalidErrorMessage = null
    },

    togglePasswordInputType () {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    },
    openResetPasswordModal () {
      this.$emit('openResetPasswordModal')
    },

    async googleOauth (authCode) {
      this.isGoogleOauthLoading = true

      try {
        const data = await auth.external({
          payload: {
            auth_code: authCode,
            agent: this.$store.state.userAgent.browserName,
            operation_system: this.$store.state.userAgent.operationSystemName,
            latitude: this.$store.state.userAgent.latitude,
            longitude: this.$store.state.userAgent.longitude,
            source: window.btoa(this.$store.state.userAgent.formattedSource + '-google')
          }
        })

        if (data.status === 200) {
          if (data.data.phone) {
            this.$emit('loginSuccess', data.token.token)
          } else {
            this.$emit('registerByOauth', {
              token: data.token.token,
              fullName: data.data.name,
              email: data.data.email
            })
          }
        }
      } finally {
        this.isGoogleOauthLoading = false
      }
    },
    async linkedinOauth () {
      this.isLinkedinOauthLoading = true

      try {
        const authCode = await this.$linkedinOauth.getAuthCode()

        const data = await auth.external({
          payload: {
            auth_code: authCode,
            agent: this.$store.state.userAgent.browserName,
            operation_system: this.$store.state.userAgent.operationSystemName,
            latitude: this.$store.state.userAgent.latitude,
            longitude: this.$store.state.userAgent.longitude,
            source: window.btoa(this.$store.state.userAgent.formattedSource + '-linkedin')
          }
        })

        if (data.status === 200) {
          if (data.data.phone) {
            this.$emit('loginSuccess', data.token.token)
          } else {
            this.$emit('registerByOauth', {
              token: data.token.token,
              fullName: data.data.name,
              email: data.data.email
            })
          }
        }
      } finally {
        this.isLinkedinOauthLoading = false
      }
    },
    async login () {
      try {
        this.isFormLoading = true
        this.unsetInvalidErrorMessages()

        const data = await auth.login({
          payload: {
            email: this.email,
            password: this.password,

            agent: this.$store.state.userAgent.browserName,
            operation_system: this.$store.state.userAgent.operationSystemName,
            latitude: this.$store.state.userAgent.latitude,
            longitude: this.$store.state.userAgent.longitude,
            source: window.btoa(this.$store.state.userAgent.formattedSource)
          }
        })

        if (data.status === 200) {
          this.$emit('loginSuccess', data.token.token)
        } else if (data.status === 400 && data.message === 'Kata sandi kadaluwarsa!') {
          this.$emit('passwordExpired', this.email)
        } else {
          this.formInvalidErrorMessage = data.message
        }
      } finally {
        this.isFormLoading = false
      }
    }
  },
  watch: {
    email: function (newEmail) {
      if (!this.formInvalidErrorMessage) {
        return false
      }

      this.formInvalidErrorMessage = null
    },
    password: function (newPassword) {
      if (!this.formInvalidErrorMessage) {
        return false
      }

      this.formInvalidErrorMessage = null
    }
  }
}
</script>

<style scoped>
.title {
  color: var(--color-persian-blue-700);
  margin-bottom: 12px;
}
.sub-title {
  color: var(--color-ui-text-primary);
}

.form-container {
  margin-top: 35px;
}

.login-separator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-placeholder);
  width: 100%;
  margin: 12px 0 20px;
}
.login-separator hr {
  flex-grow: 1;
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid var(--color-ui-element-neutral-light-3);
}
.login-separator div {
  padding: 0 10px;
}

.login-content form {
  width: 100%;
}

::placeholder {
  color: var(--color-text-placeholder);
}

.password-eye-icon-container {
  position: absolute;
  color: var(--color-ui-element-neutral-light-1);
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  display: flex;
}
.input-information-label {
  margin-top: 2px;
  margin-left: 4px;
  color: var(--color-text-placeholder);
}
.input-information-label.is-invalid {
  color: var(--color-ui-element-red-500);
}

.form-error-message {
  margin-top: 15px;
  color: var(--color-ui-element-red-500);
  text-align: center;
  display: block;
}

.form-additional-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0 44px;
}
@media screen and (min-width: 768px) {
  .form-additional-container {
    margin-bottom: 12px;
  }
}
.input-checkbox {
  display: flex;
  align-items: center;
  border-radius: 0;
}
.keep-login-checkbox {
  margin-right: 10px;
}
.label-keep-login {
  color: var(--secondary-font-color);
  vertical-align: text-bottom;
}
.forgot-password {
  color: var(--color-ui-element-neutral-dark-5);
  text-align: right;
  cursor: pointer;
}
.forgot-password:hover {
  text-decoration: underline;
}

.help-report {
  margin-top: 26px;
  color: var(--secondary-font-color);
  text-align: center;
}
@media screen and (min-width: 768px) {
  .help-report {
    margin-top: 56px;
  }
}
.help-report .whatsapp-link {
  color: inherit !important;
  display: flex;
  justify-content: center;
}
.help-report .whatsapp-link .whatsapp-icon {
  margin-right: 5px;
  color: #465A63;
}

.google-oauth-button {
  margin-bottom: 12px;
}

.button-wrapper {
  max-width: 304px;
}
</style>
