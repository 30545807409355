<template>
  <div>
    <h1 class="title font-body-1 text-center">Pendaftaran Akun</h1>
    <p class="sub-title font-paragraph-4 text-center">
      <span>Sudah punya akun?</span>
      <router-link to="login" class="ml-1">Masuk</router-link>
    </p>

    <div class="form-container">
      <form @submit.prevent="register()" autocomplete="off">
        <div class="form-group">
          <s-input-text
            v-model="fullName"
            placeholder="Nama Lengkap"
            maxlength="30"
            :is-error="!isFullNameValid"
            @input="formatFullName()" />
          <template v-if="!isFullNameValid">
            <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
            <span
              id="fullname-error-message-label"
              class="input-information-label is-invalid font-subtitle-rg-5">
              {{ fullNameInvalidErrorMessage }}
            </span>
          </template>
        </div>
        <div class="form-group">
          <email-auto-complete
            id="email-auto-complete-input"
            v-model="email"
            :domains="domains"
            :default-domains="defaultDomains"
            :max-suggestions="5"
            :is-error="!isEmailValid"
            placeholder="Email"
          />
          <template v-if="!isEmailValid">
            <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
            <span
              id="email-error-message-label"
              class="input-information-label is-invalid font-subtitle-rg-5">
              {{ emailInvalidErrorMessage }}
            </span>
          </template>
        </div>
        <div class="form-group">
          <s-input-text
            id="phone-input"
            v-model="phone"
            placeholder="Nomor ponsel aktif"
            maxlength="15"
            :is-error="!isPhoneValid"
            @input="formatPhone()"
          />
          <template v-if="!isPhoneValid">
            <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
            <span
              id="phone-error-message-label"
              class="input-information-label is-invalid font-subtitle-rg-5">
              {{ phoneInvalidErrorMessage }}
            </span>
          </template>
        </div>
        <div class="form-group">
          <div class="position-relative">
            <s-input-text
              id="password-input"
              v-model="password"
              :type="passwordInputType"
              :is-error="!isPasswordValid"
              placeholder="Kata sandi"
            />
            <div class="password-eye-icon-container">
              <img :src="passwordToggleIcon"
                alt="Show/Hide Password"
                id="password-input-type-button"
                @click="togglePasswordInputType()"
                />
            </div>
          </div>
          <template v-if="!isPasswordValid">
            <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
            <span
              id="password-error-message-label"
              class="input-information-label is-invalid font-subtitle-rg-5">
              {{ passwordInvalidErrorMessage }}
            </span>
          </template>
          <template v-else>
            <span
              id="password-information-label"
              class="input-information-label font-subtitle-rg-5"
            >
              Minimal 8 karakter kombinasi huruf besar, huruf kecil, dan angka
            </span>
          </template>
        </div>

        <p class="agreement-text font-label-sm-rg text-center">
          <span>Dengan daftar, saya menyetujui</span>
          <a :href="termsAndConditionsLink" target="_blank" class="mx-1 d-inline-block">Syarat & Ketentuan</a>
          <span>serta</span>
          <a :href="privacyPolicyLink" target="_blank" class="mx-1 d-inline-block">Kebijakan Privasi</a>
          <span>yang berlaku</span>
        </p>

        <div class="d-flex justify-content-center">
          <div class="button-wrapper w-100">
            <s-button
              id="submit-button"
              variant="primary"
              :is-loading="isFormLoading"
              disabled-button-text-color="--color-ui-text-tertiary-2"
              pill
              block>
              Daftar Sekarang
            </s-button>
          </div>
        </div>
      </form>

      <div class="d-flex justify-content-center">
        <div class="button-wrapper">
          <div class="or-divider">
            <span class="font-body-3">atau</span>
          </div>

          <google-button class="google-oauth-button" @handleCredential="googleOauth" />
          <oauth-button
            id="linkedin-oauth-button"
            :is-loading="isLinkedinOauthLoading"
            logo-image="https://cdn.sekolah.mu/assets/logo/oauth/linkedin-logo.svg"
            logo-alt="LinkedIn"
            pill
            @click="linkedinOauth()"
            >
            Daftar dengan LinkedIn
          </oauth-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import auth from '@/api/auth'
import EmailAutoComplete from '@/components/atoms/inputs/EmailAutoComplete'
import OauthButton from '@/components/atoms/buttons/OauthButton'
import SInputText from '@/components/atoms/inputs/SInputText'
import SButton from '@/components/atoms/buttons/SButton'

export default {
  name: 'RegisterForm',
  props: {
    source: String
  },
  components: {
    EmailAutoComplete,
    OauthButton,
    SInputText,
    SButton,
    GoogleButton: () => import('@/components/atoms/buttons/GoogleButton.vue')
  },
  data: () => ({
    fullName: null,
    email: null,
    phone: null,
    password: null,

    passwordInputType: 'password',

    isFullNameValid: true,
    isEmailValid: true,
    isPhoneValid: true,
    isPasswordValid: true,

    fullNameInvalidErrorMessage: null,
    emailInvalidErrorMessage: null,
    phoneInvalidErrorMessage: null,
    passwordInvalidErrorMessage: null,

    isGoogleOauthLoading: false,
    isLinkedinOauthLoading: false,
    isFormLoading: false,

    // email autocomplete
    domains: [
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'outlook.com'
    ],
    defaultDomains: [
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'outlook.com'
    ]
  }),
  computed: {
    termsAndConditionsLink () {
      if (this.source === 'talentics-jobs') {
        return 'https://www.karier.mu/syarat-ketentuan'
      }

      return 'https://www.sekolah.mu/syarat-ketentuan'
    },
    privacyPolicyLink () {
      if (this.source === 'talentics-jobs') {
        return 'https://www.karier.mu/kebijakan-privasi'
      }

      return 'https://www.sekolah.mu/kebijakan-privasi'
    },
    passwordToggleIcon () {
      return this.passwordInputType === 'password' ? 'https://cdn.sekolah.mu/assets/sso/eye-icon.svg' : 'https://cdn.sekolah.mu/assets/sso/eye-slash-icon.svg'
    }
  },
  methods: {
    formatFullName () {
      this.$nextTick(() => {
        this.fullName = this.fullName.replace(/[^a-zA-Z '-]/g, '')
      })
    },
    formatPhone () {
      this.$nextTick(() => {
        this.phone = this.phone.replace(/[^\d+]/g, '')
      })
    },

    // Validation
    validateFullName () {
      if (!this.fullName) {
        this.isFullNameValid = false
        this.fullNameInvalidErrorMessage = 'Masukkan nama lengkap.'

        return false
      }

      if (this.fullName.length < 2) {
        this.isFullNameValid = false
        this.fullNameInvalidErrorMessage = 'Masukkan nama lengkap.'

        return false
      }
    },
    validateEmail () {
      if (!this.email) {
        this.isEmailValid = false
        this.emailInvalidErrorMessage = 'Masukkan alamat email.'

        return false
      }

      if (!/^[^@ ]+@[^@ ]+\.[^@ ]+$/.test(this.email)) {
        this.isEmailValid = false
        this.emailInvalidErrorMessage = 'Format email tidak sesuai. Periksa dan coba lagi.'

        return false
      }
    },
    validatePhone () {
      if (!this.phone) {
        this.isPhoneValid = false
        this.phoneInvalidErrorMessage = 'Masukkan nomor ponsel aktif.'

        return false
      }

      if (this.phone.length < 8 || this.phone.length > 15) {
        this.isPhoneValid = false
        this.phoneInvalidErrorMessage = 'Format tidak sesuai. Periksa dan coba lagi.'

        return false
      }

      if (this.phone.includes('+')) {
        if (this.phone.split('+').length !== 2 || this.phone.split('+')[0] !== '') {
          this.isPhoneValid = false
          this.phoneInvalidErrorMessage = 'Format tidak sesuai. Periksa dan coba lagi.'

          return false
        }
      }
    },
    validatePassword () {
      if (!this.password) {
        this.isPasswordValid = false
        this.passwordInvalidErrorMessage = 'Masukkan kata sandi.'

        return false
      }

      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(this.password)) {
        this.isPasswordValid = false
        this.passwordInvalidErrorMessage = 'Format kata sandi tidak sesuai. Periksa dan coba lagi.'

        return false
      }
    },
    validateForm () {
      this.validateFullName()
      this.validateEmail()
      this.validatePhone()
      this.validatePassword()

      if (!(this.isFullNameValid && this.isEmailValid && this.isPhoneValid && this.isPasswordValid)) {
        return false
      }

      return true
    },

    togglePasswordInputType () {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    },

    async googleOauth (authCode) {
      this.isGoogleOauthLoading = true

      try {
        const data = await auth.external({
          payload: {
            auth_code: authCode,
            agent: this.$store.state.userAgent.browserName,
            operation_system: this.$store.state.userAgent.operationSystemName,
            latitude: this.$store.state.userAgent.latitude,
            longitude: this.$store.state.userAgent.longitude,
            source: window.btoa(this.$store.state.userAgent.formattedSource + '-google')
          }
        })

        if (data.status === 200) {
          if (data.data.phone) {
            this.$emit('registerSuccess', data.token.token)
          } else {
            this.$emit('registerByOauth', {
              token: data.token.token,
              fullName: data.data.name,
              email: data.data.email
            })
          }
        }
      } finally {
        this.isGoogleOauthLoading = false
      }
    },
    async linkedinOauth () {
      this.isLinkedinOauthLoading = true

      try {
        const authCode = await this.$linkedinOauth.getAuthCode()

        const data = await auth.external({
          payload: {
            auth_code: authCode,
            agent: this.$store.state.userAgent.browserName,
            operation_system: this.$store.state.userAgent.operationSystemName,
            latitude: this.$store.state.userAgent.latitude,
            longitude: this.$store.state.userAgent.longitude,
            source: window.btoa(this.$store.state.userAgent.formattedSource + '-linkedin')
          }
        })

        if (data.status === 200) {
          if (data.data.phone) {
            this.$emit('registerSuccess', data.token.token)
          } else {
            this.$emit('registerByOauth', {
              token: data.token.token,
              fullName: data.data.name,
              email: data.data.email
            })
          }
        }
      } finally {
        this.isLinkedinOauthLoading = false
      }
    },
    async register () {
      if (!this.validateForm()) {
        return false
      }

      try {
        this.isFormLoading = true

        const data = await auth.register({
          payload: {
            name: this.fullName,
            email: this.email,
            phone: this.phone,
            password: this.password,

            agent: this.$store.state.userAgent.browserName,
            operation_system: this.$store.state.userAgent.operationSystemName,
            latitude: this.$store.state.userAgent.latitude,
            longitude: this.$store.state.userAgent.longitude,
            source: window.btoa(this.$store.state.userAgent.formattedSource)
          }
        })

        if (data.status === 200) {
          this.$emit('registerSuccess', data.token.token)
        } else if (data.status === 131 && data.message === 'Email sudah terdaftar!') {
          this.isEmailValid = false
          this.emailInvalidErrorMessage = 'Email telah terdaftar. Gunakan email yang berbeda.'
        }
      } finally {
        this.isFormLoading = false
      }
    }
  },
  watch: {
    fullName: function (newFullName) {
      if (this.isFullNameValid) {
        return false
      }

      this.isFullNameValid = true
      this.fullNameInvalidErrorMessage = false
    },
    email: function (newEmail) {
      if (this.isEmailValid) {
        return false
      }

      this.isEmailValid = true
      this.emailInvalidErrorMessage = false
    },
    phone: function (newPhone) {
      if (this.isPhoneValid) {
        return false
      }

      this.isPhoneValid = true
      this.phoneInvalidErrorMessage = false
    },
    password: function (newPassword) {
      if (this.isPasswordValid) {
        return false
      }

      this.isPasswordValid = true
      this.passwordInvalidErrorMessage = false
    }
  }
}
</script>

<style scoped>
::placeholder {
  color: var(--color-text-placeholder);
}
.title {
  color: var(--color-persian-blue-700);
  margin-bottom: 12px;
}
.sub-title {
  color: var(--color-ui-text-primary);
}

.form-container {
  margin-top: 35px;
}

.or-divider {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 12px 0 20px;
}
.or-divider:before {
  content: '';
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--color-ui-element-neutral-light-3);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.or-divider span {
  position: relative;
  background-color: white;
  padding: 0 8px;
  color: var(--color-text-placeholder);
}

.password-eye-icon-container {
  position: absolute;
  color: var(--color-ui-element-neutral-light-1);
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  display: flex;
}
.input-information-label {
  margin-top: 2px;
  margin-left: 4px;
  color: var(--color-text-placeholder);
}
.input-information-label.is-invalid {
  color: var(--color-ui-element-red-500);
  margin-left: 5px;
}

.agreement-text {
  margin: 24px 0 12px;
}

.google-oauth-button {
  margin-bottom: 12px;
}

.button-wrapper {
  max-width: 304px;
}
</style>
