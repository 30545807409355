<template>
  <button
    :class="['button-font-style', `button-${variant || 'primary'}`, { 'pill-button': pill, 'w-100': block }]"
    :style="{
      '--disabled-link-color': `var(${disabledLinkColor})`,
      '--disabled-button-text-color': `var(${disabledButtonTextColor})`
    }"
    :disabled="isLoading || disabled"
    @click="onClick()"
  >
    <div class="spinner spinner-border text-secondary spinner-border-sm mr-2" role="status" v-if="isLoading">
      <span class="sr-only">Loading...</span>
    </div>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'SButton',
  props: {
    variant: {
      default: 'primary',
      type: String,
      validator: function (val) {
        return ['primary', 'link', 'secondary'].includes(val)
      }
    },
    to: {
      default: '',
      type: String
    },
    href: {
      default: '',
      type: String
    },
    target: {
      default: '',
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pill: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabledLinkColor: {
      type: String,
      default: '--disable-color'
    },
    disabledButtonTextColor: {
      type: String,
      default: '--color-ui-text-secondary'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.button-font-style {
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  height: 36px;
}

/* primary button style */
.button-primary {
  background: var(--color-persian-blue-500);
  border: 1px solid var(--color-persian-blue-500);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 20px;
  color: var(--color-background-white);
  transition: 0.3s;
}

.button-primary:hover:enabled {
  background: var(--color-persian-blue-400);
  color: var(--color-background-white);
}

/* secondary button style */
.button-secondary {
  border-radius: 5px;
  background: var(--color-background-white);
  border: 1px solid var(--highlight-font-color);
  color: var(--highlight-font-color);
  padding: 5px 20px;
  transition: 0.3s;
}

.button-secondary:hover:enabled {
  background: var(--highlight-font-color);
  color: var(--color-background-white);
}

/* optional button style */
.button-optional {
  background: var(--color-brand-sun-yellow);
  border: 1px solid var(--color-brand-sun-yellow);
  color: var(--color-background-white);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 20px;
  font-weight: 600;
  /* filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1)); */
  transition: 0.3s;
}

.button-optional:hover:enabled {
  background: var(--color-background-white);
  color: var(--color-brand-sun-yellow);
}

/* link button style */
.button-link {
  background: none !important;
  outline: none !important;
  border: none !important;
  padding: 0 !important;
  color: var(--highlight-font-color);
}

.button-link:hover:enabled {
  text-decoration: underline;
  cursor: pointer;
}

.button-link:disabled {
  color: var(--color-ui-text-secondary);
}

/* disabled button style */
.button-secondary:disabled, .button-primary:disabled, .button-optional:disabled {
  border: 1px solid var(--disable-color);
  color: var(--disabled-button-text-color);
  background: var(--disable-color);
}

/* pill button style */
.pill-button {
  border-radius: 17.5px !important;
}
</style>
