<template>
  <div>
    <h1 class="title font-title-4 text-center">Pendaftaran Akun</h1>

    <div class="form-container">
      <form @submit.prevent="register()">
        <div class="form-group">
          <s-input-text
            id="full-name-input"
            type="text"
            v-model="fullName"
            placeholder="Nama Lengkap"
            maxlength="30"
            :is-error="!isFullNameValid"
            @input="formatFullName()" />
          <template v-if="!isFullNameValid">
            <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
            <span
              id="fullname-error-message-label"
              class="input-information-label is-invalid font-subtitle-rg-5">
              {{ fullNameInvalidErrorMessage }}
            </span>
          </template>
        </div>
        <div class="form-group">
          <s-input-text
            id="email-input"
            class="input-email"
            v-model="email"
            placeholder="Email"
            type="email"
            disabled />
        </div>
        <div class="form-group">
          <s-input-text
            id="phone-input"
            v-model="phone"
            placeholder="Nomor ponsel"
            maxlength="15"
            :is-error="!isPhoneValid"
            @input="formatPhone()" />
          <template v-if="!isPhoneValid">
            <img src="https://cdn.sekolah.mu/assets/sso/circle-exclamation-mark-icon.svg" alt="Error"/>
            <span
              id="phone-error-message-label"
              class="input-information-label is-invalid font-subtitle-rg-5">
              {{ phoneInvalidErrorMessage }}
            </span>
          </template>
        </div>

        <p class="agreement-text font-label-sm-rg text-center">
          <span>Dengan daftar, saya menyetujui</span>
          <a href="https://sekolah.mu/syarat-ketentuan" target="_blank" class="mx-1">Syarat & Ketentuan</a>
          <span>serta</span>
          <a href="https://sekolah.mu/kebijakan-privasi" target="_blank" class="mx-1">Kebijakan Privasi</a>
          <span>yang berlaku</span>
        </p>

        <div class="form-group">
          <s-button
            id="submit-button"
            variant="primary"
            :is-loading="isFormLoading"
            disabled-button-text-color="--color-ui-text-tertiary-2"
            pill
            block>
            Daftar Sekarang
          </s-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import user from '@/api/user'
import SButton from '@/components/atoms/buttons/SButton'
import SInputText from '@/components/atoms/inputs/SInputText'

export default {
  name: 'RegisterOauthForm',
  props: {
    source: String,
    oauthUserInformation: Object
  },
  components: {
    SButton,
    SInputText
  },
  data: () => ({
    fullName: null,
    email: null,
    phone: null,

    isFullNameValid: true,
    isPhoneValid: true,

    fullNameInvalidErrorMessage: null,
    phoneInvalidErrorMessage: null,

    isFormLoading: false
  }),
  methods: {
    formatFullName () {
      this.$nextTick(() => {
        this.fullName = this.fullName.replace(/[^a-zA-Z '-]/g, '')
      })
    },
    formatPhone () {
      this.$nextTick(() => {
        this.phone = this.phone.replace(/[^\d+]/g, '')
      })
    },

    // Validation
    validateFullName () {
      if (!this.fullName) {
        this.isFullNameValid = false
        this.fullNameInvalidErrorMessage = 'Masukkan nama lengkap.'

        return false
      }

      if (this.fullName.length < 2) {
        this.isFullNameValid = false
        this.fullNameInvalidErrorMessage = 'Masukkan nama lengkap.'

        return false
      }
    },
    validatePhone () {
      if (!this.phone) {
        this.isPhoneValid = false
        this.phoneInvalidErrorMessage = 'Masukkan nomor ponsel aktif.'

        return false
      }

      if (this.phone.length < 8 || this.phone.length > 15) {
        this.isPhoneValid = false
        this.phoneInvalidErrorMessage = 'Format tidak sesuai. Periksa dan coba lagi.'

        return false
      }

      if (this.phone.includes('+')) {
        if (this.phone.split('+').length !== 2 || this.phone.split('+')[0] !== '') {
          this.isPhoneValid = false
          this.phoneInvalidErrorMessage = 'Format tidak sesuai. Periksa dan coba lagi.'

          return false
        }
      }
    },
    validateForm () {
      this.validateFullName()
      this.validatePhone()

      if (!(this.isFullNameValid && this.isPhoneValid)) {
        return false
      }

      return true
    },
    async register () {
      if (!this.validateForm()) {
        return false
      }

      try {
        this.isFormLoading = true

        const data = await user.profile({
          headers: {
            Authorization: this.oauthUserInformation.token
          },
          payload: {
            name: this.fullName,
            phone: this.phone
          }
        })

        if (data.status === 200) {
          this.$emit('registerSuccess', this.oauthUserInformation.token)
        }
      } finally {
        this.isFormLoading = false
      }
    }
  },
  watch: {
    fullName: function (newFullName) {
      if (this.isFullNameValid) {
        return false
      }

      this.isFullNameValid = true
      this.fullNameInvalidErrorMessage = false
    },
    phone: function (newPhone) {
      if (this.isPhoneValid) {
        return false
      }

      this.isPhoneValid = true
      this.phoneInvalidErrorMessage = false
    }
  },
  created () {
    this.fullName = this.oauthUserInformation.fullName
    this.email = this.oauthUserInformation.email
  }
}
</script>

<style scoped>
::placeholder {
  color: var(--color-text-placeholder);
}
.title {
  color: var(--color-persian-blue-700);
  margin-bottom: 12px;
}

.form-container {
  margin-top: 35px;
}
.input-email {
  background-image: url('https://cdn.sekolah.mu/assets/sso/verified-email-icon.svg');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  padding-right: 37px;
  color: var(--color-text-placeholder);
}

.input-information-label {
  margin-top: 2px;
  margin-left: 4px;
  color: var(--color-text-placeholder);
}
.input-information-label.is-invalid {
  color: var(--color-ui-element-red-500);
  margin-left: 5px;
}

.agreement-text {
  margin: 44px 0 12px;
}
</style>
