import axiosServerGolang from '@/configs/axiosServerGolang'

const baseUrl = '/auth'
const authorization = process.env.VUE_APP_APP_KEY

export default {
  async login ({ payload }) {
    try {
      const { data } = await axiosServerGolang({
        url: baseUrl + '/login',
        method: 'post',
        headers: {
          Authorization: authorization
        },
        data: {
          ...payload
        }
      })
      return data
    } catch (error) {
      return {
        status: 500,
        message: `${error}`
      }
    }
  },
  async register ({ payload }) {
    try {
      const { data } = await axiosServerGolang({
        url: baseUrl + '/register',
        method: 'post',
        headers: {
          Authorization: authorization
        },
        data: {
          ...payload
        }
      })
      return data
    } catch (error) {
      return {
        status: 500,
        message: `${error}`
      }
    }
  },
  async external ({ payload }) {
    try {
      const { data } = await axiosServerGolang({
        url: baseUrl + '/external',
        method: 'post',
        headers: {
          Authorization: authorization
        },
        data: {
          ...payload
        }
      })
      return data
    } catch (error) {
      return {
        status: 500,
        message: `${error}`
      }
    }
  },
  async sendResetPassword ({ payload }) {
    try {
      const { data } = await axiosServerGolang({
        url: baseUrl + '/send_forgot_password',
        method: 'post',
        headers: {
          Authorization: authorization
        },
        data: payload
      })
      return data
    } catch (error) {
      return {
        status: 500,
        message: `${error}`
      }
    }
  },
  async resetPassword ({ payload }) {
    try {
      const { data } = await axiosServerGolang({
        url: baseUrl + '/forgot_password',
        method: 'post',
        headers: {
          Authorization: authorization
        },
        data: payload
      })
      return data
    } catch (error) {
      return {
        status: 500,
        message: `${error}`
      }
    }
  }
}
